import FEATURES from '@/modules/common/constants/features.constant';
import VueI18n from 'vue-i18n';
// @ts-ignore
import enLocale from 'element-ui/lib/locale/lang/en';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import COMPARED_TO_SETTINGS from '@/modules/score/constants/compared-to.settings.constant';
import METRICS_SCORE from '@/modules/score/constants/metrics-score.constant';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EVENT_PRIVACY from '@/modules/events/constants/event-privacy.constant';

export const i18n = {
    $t: (str: string) => str,
};

// https://kazupon.github.io/vue-i18n/guide/formatting.html
export default <VueI18n.LocaleMessages> {
    en: {
        'price-history': 'Price History',
        'markets-history': 'History',
        'ranking-history': 'Guest reviews history',
        'demand-rate': 'Demand Rate',
        comparedTo: 'Compared to',
        hello: 'hello world',
        [FEATURES.HOME]: 'Home',
        [FEATURES.RATE]: 'Rates',
        [FEATURES.MARKET]: 'Visibility',
        [FEATURES.GUEST_REVIEW]: 'Guest reviews',
        [FEATURES.EVENTS]: 'Events Manager',
        [FEATURES.DEEP_COMPSET]: 'Compset Benchmark',
        [FEATURES.LITE_DI]: 'DI Lite',
        [FEATURES.PROMOTION_DETECTION]: 'Promotions',
        fleetDensity: 'Brokers Visibility',
        pos: 'POS',
        numberOfGuests: 'Number of Guests',
        compsetType: 'Comp Set',
        provider: 'Source',
        priceType: 'Price',
        los: 'LOS',
        roomType: 'Room Type',
        priceShown: 'Price Shown',
        mealType: 'Meal Type',
        competitors: 'Competitors',
        clear: 'Clear',
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        noData: 'No data',
        vsComp: 'vs comp',
        vsLowest: 'vs lowest',
        vsHighest: 'vs highest',
        soldOutComp: 'Sold out Compsets',
        comparisonTo: 'Comparison to',
        eventManager: 'Event Manager',
        filterSettings: {
            compset: {
                [COMPSET_TYPE.MEDIAN]: 'Median',
                [COMPSET_TYPE.HIGH]: 'Highest',
                [COMPSET_TYPE.LOW]: 'Lowest',
            },
            compset_compr: {
                [COMPSET_TYPE.MEDIAN]: 'median',
                [COMPSET_TYPE.HIGH]: 'lowest',
                [COMPSET_TYPE.LOW]: 'highest',
            },
            compset_popup_table: {
                [COMPSET_TYPE.MEDIAN]: 'Median',
                [COMPSET_TYPE.HIGH]: 'Lowest rate',
                [COMPSET_TYPE.LOW]: 'Highest rate',
            },
            provider: {
                booking: 'Booking.com',
                expedia: 'Expedia',
                google: 'Google',
                Hertz: 'Hertz',
                rentalcars: 'Rentalcars',
                Rentalcars: 'Rentalcars',
                SIXT: 'SIXT',
                agoda: 'Agoda',
                traveloka: 'Traveloka',
                ctrip: 'Ctrip',
                Ctrip: 'Ctrip',
                booking_cug: 'Booking.com CUG',
                booking_cug1: 'Booking.com CUG1',
                booking_cug2: 'Booking.com CUG2',
                booking_cug3: 'Booking.com CUG3',
                booking_mobile_app: 'Booking mobile app',
                expedia_mobile_app: 'Expedia mobile app',
                makemytrip: 'MakeMyTrip',
                hilton: 'Hilton',
                brand: 'Brand',
                average: 'All (Average)',
                bcom: 'Brand.com',
                booking_basic: 'booking.com basic',
            },
            price: {
                [PRICE_TYPE.LOWEST_FLEX]: 'Lowest flex',
                [PRICE_TYPE.LOWEST]: 'Lowest',
                [PRICE_TYPE.BEST_FLEX]: 'Best flex',
                [PRICE_TYPE.NON_REFUNDABLE]: 'Non refundable',
            },
        },
        month: {
            0: 'January',
            1: 'February',
            2: 'March',
            3: 'April',
            4: 'May',
            5: 'June',
            6: 'July',
            7: 'August',
            8: 'September',
            9: 'October',
            10: 'November',
            11: 'December',
        },
        day: {
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
            0: 'Sunday',
        },
        dayShort: {
            1: 'MON',
            2: 'TUE',
            3: 'WED',
            4: 'THU',
            5: 'FRI',
            6: 'SAT',
            0: 'SUN',
        },
        countries: {
            Canada: 'Canada',
            France: 'France',
            Germany: 'Germany',
            UK: 'UK',
            Brazil: 'Brazil',
        },
        cars: {
            title: 'Rates',
            parityTitle: 'Online Brokers',
            locationsTitle: 'Location Availability',
            notifications: 'Notifications',
            priceType: {
                [CAR_PRICE_TYPE.MEDIAN]: 'Median',
                [CAR_PRICE_TYPE.LOWEST]: 'Lowest',
                [CAR_PRICE_TYPE.HIGHEST]: 'Highest',
            },
            carClass: 'Car Category',
            fuelType: 'Fuel Type',
            provider: 'Provider',
            lor: 'LOK',
            pos: 'POS',
            bml: 'BML',
            dataSource: 'Data Source',
            pickUpCity: 'Pick Up Location',
            doors: 'Doors',
            transmission: 'Transmission',
            mileage: 'Mileage',
            competitors: 'Competitors',
            paymentTerms: 'Payment Terms',
            parity: 'Parity',
            fleetDensity: 'Brokers Visibility',
            occupancyLevel: 'Fleet Availability',
            country: 'Country',
            countryCluster: 'Country/Cluster',
        },
        score: {
            chainRange: 'Chain Range',
            comparedToSetting: {
                [COMPARED_TO_SETTINGS.CHAIN]: 'Chain Average',
                [COMPARED_TO_SETTINGS.MARKET]: 'Market Average',
            },
            metricsScore: {
                [METRICS_SCORE.MONTH]: '1M',
                [METRICS_SCORE.MONTH3]: '3M',
                [METRICS_SCORE.MONTH6]: '6M',
                [METRICS_SCORE.YTD]: 'YTD',
                [METRICS_SCORE.YEAR]: '1Y',
                [METRICS_SCORE.MAX]: 'MAX',
            },
        },
        eventsSettings: {
            [EVENT_TYPE_SETTINGS.COMMUNITY]: 'Community',
            [EVENT_TYPE_SETTINGS.CONCERTS]: 'Concerts',
            [EVENT_TYPE_SETTINGS.CONFERENCES]: 'Conferences',
            [EVENT_TYPE_SETTINGS.EXPOS]: 'Expos',
            [EVENT_TYPE_SETTINGS.FESTIVAL]: 'Festival',
            [EVENT_TYPE_SETTINGS.PERFORMING_ARTS]: 'Performing Arts',
            [EVENT_TYPE_SETTINGS.POLITICS]: 'Politics',
            [EVENT_TYPE_SETTINGS.SPORTS]: 'Sports',
            [EVENT_TYPE_SETTINGS.OTHER]: 'Other',
        },
        privacy: {
            // [EVENT_PRIVACY.MARKET]: 'Market',
            [EVENT_PRIVACY.PRIVATE]: 'Only me (Private)',
            [EVENT_PRIVACY.PUBLIC]: 'All Hotels',
        },

        ...enLocale,
    },
};
