import { container } from 'inversify-props';
import { Route, RouteConfig } from 'vue-router';
import use from '@/router/use';

import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import AuthMiddleware, { AuthMiddlewareS } from '@/router/middlewares/auth.middleware';
import LevelMiddleware, { LevelMiddlewareS } from '@/router/middlewares/level.middleware';
import CarGuard, { CarGuardS } from '@/router/guards/car.guard';

import HomepageRedirectMiddleware, { HomepageRedirectMiddlewareS } from '@/router/middlewares/homepage-redirect.middleware';
import { APP_ROUTES } from '@/modules/cars/constants/app_routes.constant';
import OnboardingMiddleware, { OnboardingMiddlewareS } from './middlewares/onboarding.middleware';

import EventsManagerRoutes from './modules/event-manager.routes';
import { CommonMenuRoutes, LockedPage } from './modules/common.routes';
import { CarMappingPopup } from './modules/cars.routes';
import { RankingHistory } from './modules/hotel/ranking.routes';
import HotelBranch from './modules/hotel';
import ChainBranch from './modules/chain';
import ClusterBranch from './modules/cluster';
import OnBoardingRoutes from './modules/onboarding.routes';
import DMSRoutes from './modules/dms.routes';
import SettingsPageRoutes from './modules/settings.routes';

function RatesScreenShotHistory(prefix: string) {
    return {
        name: `${prefix}.screenshot-popup`,
        path: 'screenshot/:provider',
        props: (route: Route) => ({
            url: route.params.url,
            date: route.params.date,
            rectangleUniqueness: route.params.rectangleUniqueness,
            provider: route.params.provider,
        }),
        component: () => import('@/modules/cars/pages/rate-screenshot.modal.page.vue'),
        meta: { modal: true },
    };
}

function CarsPriceHistory(prefix: string, ops?: { noDayParam?: boolean, category?: string }) {
    return {
        name: `${prefix}.price-history-popup`,

        // TODO: Change this param name to avoid route duplicates.
        //       For example: .../day-popup/:day/price-history/:day
        path: `price-history/${!ops?.noDayParam ? ':day' : ''}`,
        props: (route: Route) => ({ day: route.params.day, category: route.params.category }),
        component: () => import('@/modules/cars/pages/cars-price-history.modal.vue'),
        meta: { modal: true },
    };
}

function CarDayPopup(prefix: string) {
    return {
        name: `${prefix}.day-popup`,
        path: 'day-popup/:day/:shouldComparePhases?',
        component: () => import('@/modules/cars/pages/day-rate.modal.page.vue'),
        meta: { modal: true },
        children: [
            RatesScreenShotHistory(`${prefix}.day-popup`),
            CarsPriceHistory(`${prefix}.day-popup`, { noDayParam: true }),
        ],
    };
}

function CarDayCategoryPopup(prefix: string) {
    return {
        name: `${prefix}.day-popup-category`,
        path: 'day-popup-category/:day/:firstCategory/:secondCategory',
        component: () => import('@/modules/cars/pages/day-rate-category.modal.page.vue'),
        meta: { modal: true },
        children: [
            RatesScreenShotHistory(`${prefix}.day-popup-category`),
            CarsPriceHistory(`${prefix}.day-popup-category`, { noDayParam: true }),
        ],
    };
}

function FleetHistory(prefix: string) {
    return {
        name: `${prefix}.fleet-history-popup`,
        path: 'fleet-history/:historyDay',
        props: (route: Route) => ({ historyDay: route.params.historyDay, source: route.params.source }),
        component: () => import('@/modules/cars/modules/cars-price-history/pages/fleet-price-history.modal.vue'),
        meta: { modal: true },
    };
}

function FleetDayPopup(prefix: string) {
    return [
        {
            name: `${prefix}.day-popup`,
            path: 'day-popup/:day',
            props: (route: Route) => ({ day: Number(route.params.day), source: route.params.source }),
            component: () => import('@/modules/cars/modules/fleet/pages/day-rate.modal.page.vue'),
            meta: { modal: true },
            children: [
                FleetHistory(`${prefix}.day-popup`),
            ],
        },
        {
            name: `${prefix}.day-popup-source`,
            path: 'day-popup/:day/:source',
            props: (route: Route) => ({ day: Number(route.params.day), source: route.params.source }),
            component: () => import('@/modules/cars/modules/fleet/pages/day-rate.modal.page.vue'),
            meta: { modal: true },
            children: [
                FleetHistory(`${prefix}.day-popup-source`),
            ],
        },
    ];
}

function ParityComparePopup() {
    return {
        name: 'parity-compare-popup',
        path: 'parity-compare-popup',
        meta: {
            modal: true,
        },
        component: () => import('@/modules/cars/modules/parity/pages/parity-compare.modal.vue'),
    };
}

function ExcelReportGeneratorPopup(prefix: string) {
    return {
        name: `${prefix}.excel-report`,
        path: 'excel-report',
        meta: {
            modal: true,
        },
        component: () => import('@/modules/cars/pages/car-excel-report-generator.page.vue'),
    };
}

export default function initRoutes() {
    return [
        {
            path: '/auth',
            name: 'auth',
            component: () => import('@/modules/auth/pages/auth.page.vue'),
            props: (route: Route) => ({ token: route.query.token }),
        },
        {
            path: '/',
            component: () => import('@/modules/common/layouts/page.layout.vue'),
            beforeEnter: use([
                container.get<AuthMiddleware>(AuthMiddlewareS),
                container.get<LevelMiddleware>(LevelMiddlewareS),
                container.get<OnboardingMiddleware>(OnboardingMiddlewareS),
            ]),
            children: [
                {
                    path: '',
                    redirect: 'home',
                },
                {
                    path: 'home',
                    name: 'home',
                    beforeEnter: use([
                        container.get<HomepageRedirectMiddleware>(HomepageRedirectMiddlewareS),
                        container.get<FeaturesGuard>(FeaturesGuardS),
                    ]),
                    component: () => import('@/modules/home/pages/home.page.vue'),
                    children: [
                        ...CommonMenuRoutes('home'),
                        RankingHistory('home'),
                    ],
                },

                ChainBranch(),
                ClusterBranch(),
                HotelBranch(),
                DMSRoutes(),
                OnBoardingRoutes(),
                SettingsPageRoutes(),

                // NOTE: Cars Zone:
                EventsManagerRoutes(
                    'events-manager',
                    'events-manager',
                    [container.get<CarGuard>(CarGuardS)],
                ),
                {
                    path: 'cars-rates',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    children: [
                        {
                            name: 'car-rates-calendar',
                            path: '',
                            component: () => import('@/modules/cars/pages/car-calendar.page.vue'),
                            children: [
                                CarDayPopup('cars-rates'),
                                CarMappingPopup('cars-rates'),
                                ...CommonMenuRoutes('cars-rates'),
                                ExcelReportGeneratorPopup('car-rates-calendar'),
                            ],
                        },
                        {
                            name: 'car-rates-table',
                            path: 'table',
                            component: () => import('@/modules/cars/pages/car-table.page.vue'),
                            children: [
                                CarDayPopup('cars-rates.table'),
                                CarMappingPopup('cars-rates.table'),
                                ...CommonMenuRoutes('cars-rates.table'),
                                ExcelReportGeneratorPopup('car-rates-table'),
                            ],
                        },
                        {
                            name: 'car-rates-graph',
                            path: 'graph',
                            component: () => import('@/modules/cars/pages/car-graph.page.vue'),
                            children: [
                                CarDayPopup('cars-rates.graph'),
                                CarsPriceHistory('cars-rates.graph'),
                                CarMappingPopup('cars-rates.graph'),
                                ...CommonMenuRoutes('cars-rates.graph'),
                                ExcelReportGeneratorPopup('car-rates-graph'),
                            ],
                        },
                        {
                            name: 'analysis.table',
                            path: 'analysis/table',
                            component: () => import('@/modules/cars/pages/car-rates-analysis-table.page.vue'),
                            children: [
                                CarDayPopup('cars-rates.analysis.table'),
                                CarDayCategoryPopup('cars-rates.analysis.table'),
                                CarMappingPopup('cars-rates.graph'),
                                ...CommonMenuRoutes('cars-rates.graph'),
                                ExcelReportGeneratorPopup('analysis.table'),
                            ],
                        },
                    ],
                },
                {
                    name: 'parity',
                    path: 'parity',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/cars/modules/parity/pages/brokers.page.vue'),
                    children: [
                        CarMappingPopup('parity'),
                        ...CommonMenuRoutes('parity'),
                    ],
                },
                {
                    name: APP_ROUTES.notifications.index,
                    path: APP_ROUTES.notifications.index,
                    component: () => import('@/modules/cars/modules/car-notifications/pages/notification.page.vue'),
                    children: [
                        CarMappingPopup(APP_ROUTES.notifications.index),
                        CarDayPopup(APP_ROUTES.notifications.index),
                        ...CommonMenuRoutes(APP_ROUTES.notifications.index),
                    ],
                },
                {
                    path: 'fleet-density',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    children: [
                        {
                            name: 'fleet-density-calendar',
                            path: '',
                            component: () => import('@/modules/cars/modules/fleet/pages/fleet-density.page.vue'),
                            children: [
                                ...FleetDayPopup('fleet-density'),
                                FleetHistory('fleet-density'),
                                ...CommonMenuRoutes('fleet-density'),
                                CarMappingPopup('fleet-density'),
                            ],
                        },
                        {
                            name: 'fleet-table',
                            path: 'table',
                            component: () => import('@/modules/cars/modules/fleet/pages/fleet-density-table.page.vue'),
                            children: [
                                ...FleetDayPopup('fleet-density.table'),
                                ...CommonMenuRoutes('fleet-density.table'),
                                CarMappingPopup('fleet-density.table'),
                            ],
                        },
                        {
                            name: 'fleet-graph',
                            path: 'graph',
                            component: () => import('@/modules/cars/modules/fleet/pages/fleet-density-graph.page.vue'),
                            children: [
                                ...FleetDayPopup('fleet-density.graph'),
                                ...CommonMenuRoutes('fleet-density.graph'),
                                CarMappingPopup('fleet-density.graph'),
                            ],
                        },
                    ],
                },
                {
                    name: 'parity-provider',
                    path: 'parity/:provider',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/cars/modules/parity/pages/parity.page.vue'),
                    children: [
                        ParityComparePopup(),
                        CarMappingPopup('parity.provider'),
                        ...CommonMenuRoutes('parity.provider'),
                    ],
                },
                {
                    path: '/car-user-settings',
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    props: (r: Route) => ({
                        hotelId: Number(r.params.hotelId),
                    }),
                    children: [
                        {
                            name: '',
                            component: () => import('@/modules/cars/modules/settings/pages/user-settings.page.vue'),
                            path: '',
                            children: [
                                CarMappingPopup('car-user-settings'),
                                ...CommonMenuRoutes('car-user-settings'),
                            ],
                        },
                        {
                            name: 'car-user-settings.rate-alert',
                            component: () => import('@/modules/cars/modules/settings/pages/car-rate-alert-popup.page.vue'),
                            path: 'car-user-settings.rate-alert/:id?',
                            children: [],
                        },
                    ],
                },
                {
                    path: '/car-general-settings',
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    props: (r: Route) => ({
                        hotelId: Number(r.params.hotelId),
                    }),
                    children: [
                        {
                            name: '',
                            component: () => import('@/modules/cars/modules/settings/pages/general-settings.page.vue'),
                            path: '',
                            children: [
                                CarMappingPopup('car-general-settings'),
                                ...CommonMenuRoutes('car-general-settings'),
                            ],
                        },
                    ],
                },
                {
                    name: 'location-availability',
                    path: 'location-availability',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/cars/modules/location-availability/pages/location-availability.page.vue'),
                    children: [
                        CarMappingPopup('location-availability'),
                        ...CommonMenuRoutes('location-availability'),
                    ],
                },
                // NOTE: Remove this if it's unnecessary
                // {
                //     path: 'fleet-density',
                //     beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                //     component: () => import('@/modules/cars/modules/tableau/pages/fleet-density.page.vue'),
                // },
                {
                    path: 'forbidden',
                    component: () => import('@/modules/common/pages/forbidden.page.vue'),
                    children: [
                        LockedPage('forbidden'),
                    ],
                },
                {
                    path: '*',
                    component: () => import('@/modules/common/pages/not-found.page.vue'),
                },
            ],
        },
    ] as RouteConfig[];
}
