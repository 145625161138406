import { injectable, Inject } from 'inversify-props';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import * as moment from 'moment';
import * as _ from 'lodash';
import NotificationsApiService, { NotificationsApiServiceS } from './notifications-api.service';
import NotificationsStore from './store/notifications-store';
import CarsAlertsApiService, { CarsAlertsApiServiceS } from '../../alerts/cars-alerts-api.service';
import { COUNTRIES_ANY } from '../../constants/car-filter-types.constant';
import { RateAlertTrends } from '../../models/cars-base-alert-configs.model';
import { CarRateAlertType } from '../settings/enums/alerts.enum';

export const NotificationsServiceS = Symbol.for('NotificationsServiceS');
@injectable(NotificationsServiceS as unknown as string)
export default class NotificationsService {
    @Inject(NotificationsApiServiceS) private notificationsApiService!: NotificationsApiService;
    @Inject(CarsAlertsApiServiceS) private alertsApiService!: CarsAlertsApiService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: NotificationsStore = this.storeFacade.getState('NotificationsStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.alertType,
            this.storeState.selectedDatesRange,
            this.storeState.seen,
            this.storeState.LOK,
            this.storeState.POS,
            this.storeState.carCategory,
            this.storeState.competitors,
            this.storeState.country,
            this.storeState.dataSource,
            this.storeState.fuelType,
            this.storeState.trend,
            this.storeState.location,
        ], async (n, o) => {
            if (!this.storeState.loading.isLoading()) {
                this.storeState.loading.start();
                await this.loadData();
            }
        });
    }

    get notifications() {
        if (this.storeState.filterReady && this.storeState.dateFilterReady && this.storeState.loading.isLoading()) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }
        return this.storeState.notifications;
    }

    async markAsRead(id: string) {
        const res = await this.alertsApiService.markAlertAsRead(id);
        const index = this.storeState.notifications.findIndex(ins => ins.id === id);
        if (index === -1) {
            return res;
        }
        this.storeState.notifications[index].read = true;
        return res;
    }

    deleteAll() {
        const ids = this.storeState.notifications.map(notification => notification.id);
        return this.alertsApiService.deleteAlertByIds(ids);
    }

    async markAsUnRead(id: string) {
        const res = await this.alertsApiService.markAlertAsRead(id, 0);
        const index = this.storeState.notifications.findIndex(ins => ins.id === id);
        if (index === -1) {
            return res;
        }
        this.storeState.notifications[index].read = false;
        return res;
    }

    async deleteAlertById(id: string) {
        const res = await this.alertsApiService.deleteAlertById(id);
        const index = this.storeState.notifications.findIndex(ins => ins.id === id);
        if (index === -1) {
            return res;
        }
        this.storeState.notifications.splice(index, 1);
        return res;
    }

    async loadData() {
        if (
            this.storeState.alertType.length === 0
            || (this.storeState.isUserHaveBrand
            && (this.storeState.location.length === 0
            || this.storeState.competitors.length === 0
            || this.storeState.carCategory.length === 0
            || this.storeState.fuelType.length === 0
            || this.storeState.POS.length === 0
            || this.storeState.LOK.length === 0))
        ) {
            this.storeState.notifications = [];
            this.storeState.loading.finish();
            return true;
        }
        const { alertType } = this;
        const filters: Record<any, any> = {
            start_date: moment.utc(this.storeState.selectedDatesRange[0]).format('YYYY-MM-DD'),
            end_date: moment.utc(this.storeState.selectedDatesRange[1]).format('YYYY-MM-DD'),
            alert_type: alertType,
            limit: 200,
        };
        if (this.storeState.seen !== 2) {
            filters.read = this.storeState.seen;
        }

        if (this.storeState.country !== COUNTRIES_ANY) {
            filters.country = this.storeState.country;
        }
        if (this.storeState.trend !== RateAlertTrends.ANY) {
            filters.trend = this.storeState.trend;
        }
        filters.data_source = this.storeState.dataSource;
        filters.locations = this.storeState.location.length ? this.storeState.location.map(item => String(item.value)) : [];
        filters.competitors = this.storeState.competitors.length ? this.storeState.competitors.map(item => item.value) : [];
        filters.car_category = this.storeState.carCategory.length ? this.storeState.carCategory.map(item => item.value) : [];
        filters.fuel_type = this.storeState.fuelType.length ? this.storeState.fuelType.map(item => item.value) : [];
        filters.pos = this.storeState.POS.length ? this.storeState.POS.map(item => item.value) : [];
        filters.lor = this.storeState.LOK.length ? this.storeState.LOK.map(item => Number(item.value)) : [];
        this.storeState.notifications = [];
        const alerts = await this.notificationsApiService.getNotifications(filters);
        this.storeState.notifications = (alerts || []).sort((a, b) => b.date.getTime() - a.date.getTime());
        this.storeState.loading.finish();
        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get alertType(): string[] {
        return this.storeState.alertType.map(item => item.value);
    }

    get disabledFilter() {
        const { alertType } = this;
        return alertType.length > 0 && !alertType.some(type => Object.values(CarRateAlertType).includes(type as any));
    }

    get isCompetitorsDisabled(): boolean {
        const { alertType } = this;
        return (alertType.length === 1 && alertType.includes(CarRateAlertType.BRAND_DIFF.toString())) || this.disabledFilter;
    }
}
