import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import ParityApiService, { ParityApiServiceS } from './parity-api.service';
import ParitySettingsService, { ParitySettingsServiceS } from './parity-settings.service';
import ParityStore, { BmlOptions } from './store/parity.store';
import { ISort } from './interfaces/grid.interface';
import { BROKER } from '../../constants/data-source-mode.constant';

export const ParityServiceS = Symbol.for('ParityServiceS');
@injectable(ParityServiceS as unknown as string)
export default class ParityService {
    @Inject(ParityApiServiceS) private parityApiService!: ParityApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    readonly storeState: ParityStore = this.storeFacade.getState('ParityStore');

    private isLoading = false;

    constructor() {
        this.storeFacade.watch(
            () => [
                this.storeState.settings.pickUpCityCodes,
                this.storeState.settings.lor,
                this.storeState.settings.pos,
                this.storeState.settings.carClasses,
                this.storeState.settings.doors,
                this.storeState.settings.mileage,
                this.storeState.settings.transmission,
                this.storeState.settings.paymentTerm,
                this.storeState.settings.bml,
                this.storeState.settings.provider,
                this.carsSharedService.filters.chainMode,
                this.storeState.settings.dataSources,
            ],
            this.resetLoading.bind(this),
        );

        this.storeFacade.watch(
            () => [
                this.storeState.table.sort,
                this.storeState.settings.priceDif,
            ],
            this.resetGridLoading.bind(this),
        );

        this.storeFacade.watch(
            () => this.storeState.settings.pickUpDate,
            () => {
                this.resetGridLoading();
                this.resetSpreadLoading();
            },
        );
    }

    async loadTableData(): Promise<boolean> {
        const settings = {
            page: 1,
            pageLimit: this.tablePageLimit,
        };

        if (!this.paritySettingsService.isAllFiltersSetUp) {
            return false;
        }

        const filterSettings = this.storeState.settings;
        const sorting: ISort = this.storeState.table.sort;

        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;

        if (!chainMode || !currentCompany) {
            return false;
        }

        const [count, grid] = await Promise.all([
            this.carsService.isBroker ? this.parityApiService.getBmlCount(filterSettings, currentCompany, chainMode) : null,
            this.carsService.isBroker ? this.parityApiService.getBmlGrid(settings, filterSettings, sorting, currentCompany, chainMode) : null,
        ]);

        this.storeState.table.documents = grid;
        this.storeState.table.documentsCount = count;

        return true;
    }

    async loadSpreadData() {
        const filterSettings = this.storeState.settings;

        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;

        if (!chainMode || !currentCompany) {
            return false;
        }

        if (!this.paritySettingsService.isAllFiltersSetUp) {
            return false;
        }

        const spread = this.carsService.isBroker ? await this.parityApiService.getLatestSpread(filterSettings, currentCompany, chainMode) : null;
        this.storeState.spread.data = spread;

        return true;
    }

    async loadTrendData() {
        const filterSettings = this.storeState.settings;

        if (!this.paritySettingsService.isAllFiltersSetUp) {
            return false;
        }

        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;

        if (!chainMode || !currentCompany) {
            return false;
        }

        const trend = this.carsService.isBroker ? await this.parityApiService.getLatestTrending(filterSettings, currentCompany, chainMode) : null;
        this.storeState.trend.data = trend;

        return true;
    }

    async loadBrokersData(): Promise<boolean> {
        const filterSettings = this.storeState.settings;
        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;
        const { brokersCompetitors } = this.carsService;

        if (!this.paritySettingsService.pickUpCities || !chainMode || !currentCompany) {
            return false;
        }

        if (!brokersCompetitors.length && chainMode !== BROKER) {
            return false;
        }

        this.storeState.brokers.items = this.carsService.isBroker
            ? await this.parityApiService.getBrokers(filterSettings, currentCompany, chainMode, brokersCompetitors) : null;
        this.storeState.brokers.itemsCount = 1;
        return true;
    }

    async loadMapData(): Promise<boolean> {
        const filterSettings = this.storeState.settings;
        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;
        const { brokersCompetitors } = this.carsService;

        if (!chainMode || !currentCompany || !brokersCompetitors.length) {
            return false;
        }

        this.storeState.map.data = this.carsService.isBroker
            ? await this.parityApiService.getKpisMap(filterSettings, currentCompany, chainMode, brokersCompetitors) : null;
        return true;
    }

    async loadKpisData(): Promise<boolean> {
        const filterSettings = this.storeState.settings;
        const { brokersCompetitors } = this.carsService;
        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;

        if (!this.paritySettingsService.pickUpCities || !brokersCompetitors || !brokersCompetitors.length) {
            return false;
        }

        if (!chainMode || !currentCompany) {
            return false;
        }

        this.storeState.kpis.data = this.carsService.isBroker
            ? await this.parityApiService.getKpis(filterSettings, currentCompany, chainMode, brokersCompetitors) : null;
        return true;
    }

    async loadBrokersPerformanceData(): Promise<boolean> {
        const filterSettings = this.storeState.settings;
        const { brokersCompetitors } = this.carsService;

        if (!this.paritySettingsService.pickUpCities) {
            return false;
        }

        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;

        if (!chainMode || !currentCompany) {
            return false;
        }

        if (!brokersCompetitors.length && chainMode !== BROKER) {
            return false;
        }

        const performance = this.carsService.isBroker
            ? await this.parityApiService.getBrokersPerformance(filterSettings, currentCompany, chainMode, brokersCompetitors) : null;
        this.storeState.brokersPerformance.data = performance;

        return true;
    }

    // For lazy loading
    async loadMoreTableData() {
        if (!this.storeState.table.documents || !this.storeState.table.documentsCount || this.isLoading) {
            return;
        }

        const { documentsCount } = this.storeState.table;
        const downloadedDocumentsCount = this.storeState.table.documents.length;

        const sorting: ISort = this.storeState.table.sort;

        const page = downloadedDocumentsCount / this.tablePageLimit + 1;

        if (downloadedDocumentsCount >= documentsCount) {
            return;
        }

        const settings = {
            page,
            pageLimit: this.tablePageLimit,
        };

        // if (!this.paritySettingsService.isAllFiltersSetUp) {
        //     return;
        // }

        const filterSettings = this.storeState.settings;
        this.isLoading = true;
        // this.storeState.table.loading.start();

        const { chainMode } = this.carsSharedService;
        const { currentCompany } = this.userService;

        if (!chainMode || !currentCompany) {
            return;
        }

        const documents = this.carsService.isBroker
            ? await this.parityApiService.getBmlGrid(settings, filterSettings, sorting, currentCompany, chainMode) : null;

        if (documents) {
            const moreDocuments = this.storeState.table.documents.concat(documents);
            this.storeState.table.documents = moreDocuments;
        }
        this.isLoading = false;
        // this.storeState.table.loading.finish();
    }

    get brokers() {
        this.helperService.dynamicLoading(this.storeState.brokers.loading, this.loadBrokersData.bind(this));
        return this.storeState.brokers.items;
    }

    get map() {
        this.helperService.dynamicLoading(this.storeState.map.loading, this.loadMapData.bind(this));
        return this.storeState.map.data;
    }

    get kpis() {
        this.helperService.dynamicLoading(this.storeState.kpis.loading, this.loadKpisData.bind(this));
        return this.storeState.kpis.data;
    }

    get tableDocuments() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.table.loading, this.loadTableData.bind(this));
        }
        return this.storeState.table.documents;
    }

    get tableCount(): number | null {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.table.loading, this.loadTableData.bind(this));
        }
        return this.storeState.table.documentsCount;
    }

    get spreadData() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.spread.loading, this.loadSpreadData.bind(this));
        }
        return this.storeState.spread.data;
    }

    get trendData() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.trend.loading, this.loadTrendData.bind(this));
        }
        return this.storeState.trend.data;
    }

    get brokersPerformanceData() {
        this.helperService.dynamicLoading(this.storeState.brokersPerformance.loading, this.loadBrokersPerformanceData.bind(this));
        return this.storeState.brokersPerformance.data;
    }

    get currentDocument() {
        if (!this.currentDocumentId) {
            return null;
        }

        return this.getDocumentById(this.currentDocumentId);
    }

    get currentDocumentId() {
        return this.storeState.table.currentDocumentId;
    }

    set currentDocumentId(id: number | null) {
        this.storeState.table.currentDocumentId = id;
    }

    get tablePageLimit() {
        return this.storeState.table.pageLimit;
    }

    set tableSort(sort: ISort) {
        this.storeState.table.sort = sort;
    }

    get trendDisabled() {
        return this.storeState.trend.disabled;
    }
    set trendDisabled(options: BmlOptions[]) {
        this.storeState.trend.disabled = options;
    }

    getDocumentById(id: number) {
        if (!this.storeState.table.documents) {
            return null;
        }

        return this.storeState.table.documents.find(doc => doc.id === id) || null;
    }

    resetGridLoading() {
        this.storeState.table.documents = null;
        this.storeState.table.loading.reset();
    }

    resetSpreadLoading() {
        this.storeState.spread.data = null;
        this.storeState.spread.loading.reset();
    }

    resetTrendLoading() {
        this.storeState.trend.data = null;
        this.storeState.trend.loading.reset();
    }

    resetPerformanceLoading() {
        this.storeState.brokersPerformance.data = null;
        this.storeState.brokersPerformance.loading.reset();
    }

    resetKpisLoading() {
        this.storeState.kpis.data = null;
        this.storeState.kpis.loading.reset();
    }

    resetMapLoading() {
        this.storeState.map.data = null;
        this.storeState.map.loading.reset();
    }

    resetBrokersLoading() {
        this.storeState.brokers.items = null;
        this.storeState.brokers.loading.reset();
    }

    resetLoading() {
        this.resetTrendLoading();
        this.resetSpreadLoading();
        this.resetGridLoading();
        this.resetPerformanceLoading();
        this.resetKpisLoading();
        this.resetBrokersLoading();
        this.resetMapLoading();
    }
}
