import _ from 'lodash';
import { Inject, injectable } from 'inversify-props';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import LocationAvailabilityStore from '@/modules/cars/modules/location-availability/store/location-availability.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';

export const LocationAvailabilityFiltersServiceS = Symbol.for('LocationAvailabilityFiltersServiceS');
@injectable(LocationAvailabilityFiltersServiceS as unknown as string)
export default class LocationAvailabilityFiltersService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    readonly storeState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');

    private defaultFilterValues: { [key: string]: any } = {};
    private userFilterStorageKey = 'loc-availability';

    constructor() {
        let finishUpdateOfDefaultFilters: any = false;

        this.initFilters()
            .then(() => {
                // Remember original chain defaults on first load.
                this.defaultFilterValues = JSON.parse(JSON.stringify(this.extractUserFilterValues()));

                // Apply user defaults saved in localStorage
                const settings = this.carsSharedService.getUserFilterValues(this.userFilterStorageKey);
                if (settings) {
                    // Setup filters which affect document load
                    Object.assign(this.storeState.settings, {
                        // These filters can trigger document reload
                        country: settings.country,
                        pickUpCityCodes: settings.pickUpCityCodes,
                        allPickUpCityCodes: settings.allPickUpCityCodes,
                        pos: settings.pos,
                    });

                    this.updateLocations(false, false);
                    this.storeState.settings.providersPreserve = true;

                    // Remember filters to update after document load
                    finishUpdateOfDefaultFilters = {
                        // These don't reload doc
                        providers: settings.providers,
                        lor: settings.lor,
                        isClosedOnly: settings.isClosedOnly,
                    };
                }

                // Update filters on document load
                this.storeFacade.watch(() => this.storeState.documents, async () => {
                    // Document can be NULL if we reset filters.
                    if (this.storeState.documents) {
                        this.refreshFilters();

                        // Finish update of stored defaults. Update is executed only one time on first document load.
                        if (finishUpdateOfDefaultFilters) {
                            Object.assign(this.storeState.settings, finishUpdateOfDefaultFilters);
                            this.storeState.settings.providersPreserve = false;
                            finishUpdateOfDefaultFilters = false;
                        }
                    }
                });

                this.storeFacade.watch(() => this.storeState.settings.pos, () => {
                    if (this.storeState.filtersReady) {
                        // prevent incorrect selected values from other pages
                        this.initPos();
                        this.updateLocations(false);
                    }
                });

                this.storeFacade.watch(() => this.storeState.settings.country, () => {
                    if (this.storeState.filtersReady) {
                        this.initPos();
                        this.updateLocations(false);
                    }
                });

                // Mark filters as ready
                this.storeState.filtersReady = true;
            });
    }

    async initFilters() {
        await this.initCountries();
        await this.initPos();
        await this.initLor();

        // After country and pos are initialized
        this.updateLocations(false);
    }

    /**
     * After document load. These filters don't trigger document reload.
     */
    refreshFilters() {
        this.refreshProviders();
        this.refreshLor();
    }

    async initCountries() {
        const currentCountry = this.storeState.settings.country;
        if (!currentCountry) {
            [this.storeState.settings.country] = this.countryNames;
        }
    }

    async initPos() {
        const { availablePos } = this;
        if (!this.storeState.settings.pos || !availablePos.find(pos => pos === this.storeState.settings.pos)) {
            const [defaultPos] = availablePos;
            this.storeState.settings.pos = defaultPos;
        }
    }

    async initLor() {
        const { lor } = this.carFiltersSettings;
        if (lor) {
            [this.storeState.settings.lor] = lor;
        }
    }

    refreshProviders() {
        if (!this.storeState.settings.providersPreserve) {
            this.storeState.settings.providers = this.providersList;
        }
    }

    refreshLor() {
        const { availableLors } = this;
        if (!this.storeState.settings.lor || !availableLors.find(lor => lor === this.storeState.settings.lor)) {
            const [defaultLor] = availableLors;
            this.storeState.settings.lor = defaultLor;
        }
    }

    getLocationsBySelectedCountry(country: string, query?: string | null) {
        const { countries, allowedPosCodeLocations } = this.carFiltersSettings;
        let { locations } = this.carFiltersSettings;

        if (!locations || !countries || !allowedPosCodeLocations) {
            return [];
        }
        let allowedPosCodesLocationIds: string[] = [];
        const modifiedCountries = { ...countries };
        modifiedCountries[COUNTRIES_ANY] = Object.values(countries).flat();

        Object.values(allowedPosCodeLocations).forEach(posLocations => {
            allowedPosCodesLocationIds = allowedPosCodesLocationIds.concat(Object.keys(posLocations));
        });

        allowedPosCodesLocationIds = Array.from(new Set(allowedPosCodesLocationIds));
        const availableLocationsSet = new Set(_.intersection(allowedPosCodesLocationIds, modifiedCountries[country]));

        locations = locations.filter(location => availableLocationsSet.has(location.locationId));

        if (query) {
            const searchPattern = new RegExp(query.toLowerCase());
            locations = locations.filter(location => searchPattern.test(location.locationName.toLowerCase()));
        }

        return locations.sort((a, b) => {
            if (a.locationName > b.locationName) {
                return 1;
            }
            return -1;
        });
    }

    updateLocations(isByUserSelection?: boolean, updateValues: boolean = true) {
        const { country, pos } = this.storeState.settings;
        const { allowedPosCodeLocations, carLocations } = this.carsSharedService.filters;
        let locations = country ? this.getLocationsBySelectedCountry(country) : [];
        locations = locations.filter(loc => _.get(allowedPosCodeLocations, `${pos}.${loc.locationId}`));
        if (carLocations && carLocations.length) {
            const countryOnlyLocationId = carLocations.map(item => item.locationId);
            locations = locations.filter(item => countryOnlyLocationId.includes(item.locationId));
        }
        const locationIds = locations.map(loc => loc.locationId);
        if (updateValues) {
            if (isByUserSelection) {
                this.carsSharedService.savePickUpCitiesAndCountry(locationIds, 'location-availability');
            } else {
                this.storeState.settings.pickUpCityCodes = locationIds;
            }
        }
        this.currentPickupLocations = locations.map(loc => ({ name: loc.locationName, value: loc.locationId }));
    }

    get carFiltersSettings() {
        return this.carsSharedService.filters;
    }

    get countryNames(): string[] {
        const { countries } = this.carFiltersSettings;

        if (!countries) {
            return [];
        }

        const countryNames = new Set(Object.keys(countries));

        return Array.from(countryNames)
            .filter(country => this.getLocationsBySelectedCountry(country).length > 0);
    }

    get locationQuery() {
        return this.storeState.locationQuery;
    }

    set locationQuery(value) {
        this.storeState.locationQuery = value;
    }

    get locations() {
        const { country } = this.storeState.settings;
        return country ? this.getLocationsBySelectedCountry(country, this.locationQuery) : [];
    }

    get providersList() {
        const { documents } = this.storeState;
        const { allowedBrands, dataSources, posProviderHidden } = this.carsSharedService.filters;
        const { locationAvailabilityPos } = this.carsSharedService;
        const { lor } = this.storeState.settings;
        let providers: string[] = [];
        if (documents) {
            Object.values(documents).forEach((loc: LocationAvailabilityDocumentModel) => {
                if (loc.pickupDates) {
                    Object.values(loc.pickupDates).forEach(pickupDate => {
                        const allProvider: string[] = [];
                        Object.keys(pickupDate).forEach(provider => {
                            if (lor && Object.keys(pickupDate[provider]).includes(String(lor))) {
                                allProvider.push(provider);
                            }
                        });
                        providers = providers.concat(allProvider);
                    });
                }
            });
            providers = Array.from(new Set(providers));
        }
        const availableProviders = _.union(allowedBrands, dataSources);
        providers = _.intersection(availableProviders, providers);
        const list = locationAvailabilityPos && posProviderHidden[locationAvailabilityPos]
            ? providers.filter(provider => !posProviderHidden[locationAvailabilityPos].includes(provider)) : providers;
        this.storeState.settings.providers = list;
        return list;
    }

    get availablePos() {
        const { availability } = this.carsSharedService.filters;
        const { locations } = this;
        if (!availability || !locations) {
            return [];
        }
        const pickUpCityCodes = locations.map(location => location.locationId);
        let availablePoses: string[] = [];

        availability
            .filter(doc => pickUpCityCodes.includes(doc.id))
            .forEach(doc => {
                const locationPoses = Object.values(doc.path).map(val => Object.keys(val)).flat();
                availablePoses = availablePoses.concat(locationPoses);
            });

        return Array.from(new Set(availablePoses));
    }

    get availableLors() {
        const { documents } = this.storeState;
        let lor: number[] = [];
        if (documents) {
            Object.values(documents).forEach((loc: LocationAvailabilityDocumentModel) => {
                if (loc.pickupDates) {
                    Object.values(loc.pickupDates).forEach(pickupDate => {
                        Object.values(pickupDate).forEach(provider => {
                            lor = lor.concat(
                                Object.keys(provider).map(item => Number(item)).filter(item => !_.isNaN(item)),
                            );
                        });
                    });
                }
            });
            lor = Array.from(new Set(lor)).sort((a, b) => a - b);
        }
        return lor;
    }

    // Filter values

    get isClosedOnly() {
        return this.storeState.settings.isClosedOnly;
    }

    set isClosedOnly(value) {
        this.storeState.settings.isClosedOnly = value;
    }

    get currentPickupLocations() {
        return this.storeState.currentPickupLocations;
    }

    set currentPickupLocations(value) {
        this.storeState.currentPickupLocations = value;
    }

    get pickUpLocations() {
        return [...(this.currentPickupLocations || [])]
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                return -1;
            });
    }

    set pickUpLocations(locations: { name: string, value: string }[] | null) {
        this.storeState.settings.pickUpCityCodes = locations ? locations.map(loc => loc.value) : [];
        this.currentPickupLocations = locations && locations.length ? locations : [];
    }

    get lor() {
        return this.storeState.settings.lor;
    }

    set lor(lor: number | null) {
        this.storeState.settings.lor = lor;
    }

    get pos() {
        return this.storeState.settings.pos;
    }

    set pos(pos: string | null) {
        this.storeState.settings.pos = pos;
    }

    get country() {
        return this.storeState.settings.country;
    }

    set country(country: string | null) {
        this.storeState.settings.country = country;
    }

    get providers() {
        return this.storeState.settings.providers;
    }

    set providers(provider: string[] | null) {
        this.storeState.settings.providers = provider;
    }

    get pickUpCityCodes() {
        return this.storeState.settings.pickUpCityCodes;
    }

    set allowedPickUpLocationIds(value: any) {
        this.storeState.settings.allPickUpCityCodes = value;
    }

    saveUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, this.extractUserFilterValues(), key);
    }

    clearUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, false, key);

        if (!this.defaultFilterValues) {
            document.location.reload();
        }

        // Prevent document load.
        this.storeState.filtersReady = false;

        const settings = this.defaultFilterValues;

        Object.assign(this.storeState.settings, {
            country: settings.country,
            pickUpCityCodes: settings.pickUpCityCodes,
            allPickUpCityCodes: settings.allPickUpCityCodes,
            providers: settings.providers,
            pos: settings.pos,
            lor: settings.lor,
            isClosedOnly: settings.isClosedOnly,
        });

        // Mark filters as ready and trigger data load
        this.storeState.filtersReady = true;
    }

    extractUserFilterValues() {
        const { settings } = this.storeState;
        return {
            country: settings.country,
            pickUpCityCodes: settings.pickUpCityCodes,
            allPickUpCityCodes: settings.allPickUpCityCodes,
            providers: settings.providers,
            pos: settings.pos,
            lor: settings.lor,
            isClosedOnly: settings.isClosedOnly,
        };
    }
}
